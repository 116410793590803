import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import SimpleLayout from '../layouts/simple';
import CompactLayout from '../layouts/compact';
//
import {
  // Travel
  TravelBlogPage,
  TravelPostPage,
  TravelTourPage,
  TravelAboutPage,
  TravelToursPage,
  TravelContactPage,
  TravelLandingPage,
  TravelCheckoutPage,
  TravelOrderCompletedPage,
  // E-Learning
  ElearningBlogPage,
  ElearningPostPage,
  ElearningAboutPage,
  ElearningCoursePage,
  ElearningContactPage,
  ElearningCoursesPage,
  ElearningLandingPage,
  // E-Commerce
  EcommerceCartPage,
  EcommerceComparePage,
  EcommerceLandingPage,
  EcommerceYouthEmpowerPage,

  EcommerceTripPackagePage,
  EcommerceTripPackageLocationPage,
  EcommerceTripPackageListingPage,
  EcommerceFoodDirectoryPage,

  MerchantProductView,
  EcommerceProductPage,
  EcommerceMerchantChat,
  EcommerceCheckoutPage,
  EcommerceProductsPage,
  EcommerceWishlistPage,
  EcommerceAccountOrdersPage,
  EcommerceAccountOrdersDetailPage,
  EcommerceAccountOrdersDetailPageExt,
  EcommerceOrderCompletedPage,
  EcommerceAccountPaymentPage,
  EcommerceAccountPersonalPage,
  EcommerceAccountAddressPage,
  EcommerceAccountVouchersPage,
  EcommerceAccountWishlistPage,
  EcommerceAppProceedCart,
  EcommerceProceedBuyNowCart,
  // SITE
  SitePageHowToBuy,
  SitePageAboutUs,
  SitePageTerms,
  SitePagePolicy,
  SitePagePolicyKTC,
  SitePageContactUs,
  SitePageFaq,
  SitePageShippingDelivery,
  SitePagePartnership,
  // Auth
  LoginCoverPage,
  RegisterCoverPage,
  ResetPasswordPage,
  RegisterMerchantPage,
  EcommerceMerchantProductPage,

  Page404,
  BooklahEventLandingPage,
  BooklahEventEventPage,
  BooklahEventCheckoutPage,
  BooklahEventBuyNow
} from './elements';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Non layout
    {
      path: 'auth',
      children: [
        { path: 'login-cover', element: <LoginCoverPage /> },
        { path: 'register-cover', element: <RegisterCoverPage /> },
        { path: 'reset-password', element: <ResetPasswordPage /> },
        { path: 'register-merchant', element: <RegisterMerchantPage /> },
      ],
    },
    // E-commerce
    {
      children: [
        { element: <EcommerceLandingPage />, index: true },
        { path: 'products/:keyword/:name', element: <EcommerceProductsPage /> },
        { path: 'product/:name', element: <EcommerceProductPage /> },
        { path: 'merchant/chat', element: <EcommerceMerchantChat /> },
        { path: 'merchantViewProducts', element: <MerchantProductView /> },
        { path: 'cart', element: <EcommerceCartPage /> },
        { path: 'checkout', element: <EcommerceCheckoutPage /> },
        { path: 'shop/ShopPageReceipt/:paymentType/:transactionNo', element: <EcommerceOrderCompletedPage /> },
        { path: 'wishlist', element: <EcommerceWishlistPage /> },
        { path: 'compare', element: <EcommerceComparePage /> },
        { path: 'merchantProduct/:merchantID', element: <EcommerceMerchantProductPage /> },
        {
          path: 'account',
          children: [
            { path: 'personal', element: <EcommerceAccountPersonalPage /> },
            { path: 'address', element: <EcommerceAccountAddressPage /> },
            { path: 'wishlist', element: <EcommerceAccountWishlistPage /> },
            { path: 'vouchers', element: <EcommerceAccountVouchersPage /> },
            { path: 'orders', element: <EcommerceAccountOrdersPage /> },
            { path: 'orderDetail/:id', element: <EcommerceAccountOrdersDetailPageExt /> },
            { path: 'payment', element: <EcommerceAccountPaymentPage /> },
          ],
        },
        { path: 'youthEmpower', element: <EcommerceYouthEmpowerPage /> },
        { path: 'emporiaapp/:type/:id', element: <EcommerceAppProceedCart /> },
        { path: 'emporiabuynow/:id', element: <EcommerceProceedBuyNowCart /> },
      ],
    },
    {
      path: 'hotel',
      children: [
        { path: 'landing', element: <TravelLandingPage /> },
        { path: 'lists', element: <TravelToursPage /> },
        { path: 'list/:id', element: <TravelTourPage /> },
        { path: 'checkout', element: <TravelCheckoutPage /> },
        { path: 'order-completed', element: <TravelOrderCompletedPage /> },
        { path: 'posts', element: <TravelBlogPage /> },
        { path: 'post', element: <TravelPostPage /> },
        { path: 'about', element: <TravelAboutPage /> },
        { path: 'contact', element: <TravelContactPage /> },
      ],
    },
    // E-learning
    {
      path: 'e-learning',
      children: [
        { path: 'landing', element: <ElearningLandingPage /> },
        { path: 'courses', element: <ElearningCoursesPage /> },
        { path: 'course', element: <ElearningCoursePage /> },
        { path: 'posts', element: <ElearningBlogPage /> },
        { path: 'post', element: <ElearningPostPage /> },
        { path: 'about', element: <ElearningAboutPage /> },
        { path: 'contact', element: <ElearningContactPage /> },
      ],
    },
    // Booklah Event
    {
      path: 'tripPackage',
      children: [
        { path: 'landing', element: <EcommerceTripPackageListingPage /> },
        { path: 'package/:locationId', element: <EcommerceTripPackagePage /> },
        { path: 'packageLocation/:tripLocationDetailsID', element: <EcommerceTripPackageLocationPage /> },

      ],
    },


    // Trip Package
    {
      path: 'directory',
      children: [
        { path: 'landing', element: <EcommerceFoodDirectoryPage /> },
        // { path: 'event/:id', element: <BooklahEventEventPage /> },
        // { path: 'checkout', element: <BooklahEventCheckoutPage /> },
        // { path: 'buynow/:id', element: <BooklahEventBuyNow /> },
        // { path: 'ticketDetail/:id', element: <EcommerceAccountOrdersDetailPageExt /> },

      ],
    },
    // Trip Package
    {
      path: 'booklahEvent',
      children: [
        { path: 'landing', element: <BooklahEventLandingPage /> },
        { path: 'event/:id', element: <BooklahEventEventPage /> },
        { path: 'checkout', element: <BooklahEventCheckoutPage /> },
        { path: 'buynow/:id', element: <BooklahEventBuyNow /> },
        { path: 'ticketDetail/:id', element: <EcommerceAccountOrdersDetailPageExt /> },

      ],
    },
    {
      path: 'site',
      children: [
        { path: 'about-us', element: <SitePageAboutUs /> },
        { path: 'terms', element: <SitePageTerms /> },
        { path: 'policy', element: <SitePagePolicy /> },
        { path: 'policyktc', element: <SitePagePolicyKTC noHeader/> },
        { path: 'contact-us', element: <SitePageContactUs /> },
        { path: 'faq', element: <SitePageFaq /> },
        { path: 'howtobuy', element: <SitePageHowToBuy /> },
        { path: 'shippingdelivery', element: <SitePageShippingDelivery /> },
        { path: 'partnership', element: <SitePagePartnership /> },
      ],
    },

    { path: '*', element: <Page404 /> },
  ]);
}

import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar';
import kanbanReducer from './slices/kanban';
import userReducer from './slices/user';
import profileReducer from './slices/profile';
import journalReducer from './slices/journal';
import questionReducer from './slices/question';
import generalReducer from './slices/general';
import orderReducer from './slices/order';
import categoryReducer from './slices/category';
import hotelReducer from './slices/hotel';
import registerMerchantReducer from './slices/registerMerchant';
import eventReducer from './slices/event';
import tripPackageReducer from './slices/tripPackage';
import directoryReducer from './slices/directory';


// ----------------------------------------------------------------------

export const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

export const journalPersistConfig = {
  key: 'journal',
  storage,
  keyPrefix: 'redux-',
  whitelist: [''],
};

export const authorPersistConfig = {
  key: 'author',
  storage,
  keyPrefix: 'redux-',
  whitelist: [''],
};

export const categoryPersistConfig = {
  key: 'category',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['categories'],
};

export const registerMerchantPersistConfig = {
  key: 'registerMerchant',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['registerMerchant'],
};

export const chatPersistConfig = {
  key: 'chat',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['chat'],
};

const rootReducer = combineReducers({
  mail: mailReducer,
  chat: persistReducer(chatPersistConfig, chatReducer),
  calendar: calendarReducer,
  kanban: kanbanReducer,
  product: persistReducer(productPersistConfig, productReducer),
  user: userReducer,
  profile: profileReducer,
  journal: persistReducer(journalPersistConfig, journalReducer),
  question: questionReducer,
  general: generalReducer,
  order: orderReducer,
  category: persistReducer(categoryPersistConfig, categoryReducer),
  hotel: hotelReducer,
  registerMerchant: registerMerchantReducer,
  tripPackage: tripPackageReducer,
  event: eventReducer,
  directory: directoryReducer,
});

export default rootReducer;

import { createSlice } from '@reduxjs/toolkit';
import { createContext, useEffect, useReducer, useCallback, useMemo } from 'react';
// utils
import axios from '../../utils/axios';
import { ServerConfiguration } from '../../utils/serverConfig';
import { setUser } from '../../auth/utils';

// ----------------------------------------------------------------------

const url = ServerConfiguration.testingServerUrl;

const projectId = 2;

const initialState = {
    isLoading: false,
    error: null,
    hotelListByType: null,
    hotelListByDate: null,
    hotelListLanding: null,
};

const slice = createSlice({
    name: 'hotel',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        getHotelItemListByTypeSuccess(state, action) {
            state.isLoading = false;
            state.hotelListByType = action.payload;
        },

        getHotelItemListByDateSuccess(state, action) {
            state.isLoading = false;
            state.hotelListByDate = action.payload;
        },

        getHotelListLandingSuccess(state, action) {
            state.isLoading = false;
            state.hotelListLanding = action.payload;
        },

        // addDesignYouthEmpowermentSuccess(state, action) {
        //     state.isLoading = false;
        //     state.youthEmpowerAction = action.payload;
        // },

        // clearYouthEmpowerActionSuccess(state, action) {
        //     state.isLoading = false;
        //     state.youthEmpowerAction = null;
        // },

    },
});

// Reducer
export default slice.reducer;

// Actions
export const { onOpenModal, onCloseModal, selectUser, selectRange, startLoading } = slice.actions;

// ----------------------------------------------------------------------

export const AuthContext = createContext(null);

// TYPE: MERCHANT/ HOTELID /CITY; TYPEVALUE: 0/ specific hotelId / "Kuching"
export function getHotelItemListByType(type, typeValue, userId, productPerPage, page) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await fetch(
                `${url}Hotel_ItemListByType?TYPE=${type}&TYPEVALUE=${typeValue}&USERID=${userId}&PROJECTID=${projectId}&PRODUCTPERPAGE=${productPerPage}&PAGE=${page}`
            )
            const json = await response.json();
            const data = JSON.parse(json)[0];
            if (data && data.ReturnVal === 1) {
                dispatch(slice.actions.getHotelItemListByTypeSuccess(JSON.parse(data.ReturnData)));
            } else {
                dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

//  IF No City to filter City='-'
export function getHotelItemListByDate(hotelId, startDate, endDate, adultNo, childNo, city) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await fetch(
                `${url}Hotel_ItemListByDate?HOTELID=${hotelId}&STARTDATE=${startDate}&ENDDATE=${endDate}&PROJECTID=${projectId}&ADULTNO=${adultNo}&CHILDNO=${childNo}&CITY=${city}`
            )
            const json = await response.json();
            const data = JSON.parse(json)[0];
            if (data && data.ReturnVal === 1) {
                dispatch(slice.actions.getHotelItemListByDateSuccess(JSON.parse(data.ReturnData)));
            } else {
                dispatch(slice.actions.getHotelItemListByDateSuccess(JSON.parse(data.ReturnData)));
                // dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getHotelListLanding(hotelId, productPerPage, page) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await fetch(
                `${url}Hotel_ItemListInLandingPage?HOTELID=${hotelId}&PROJECTID=${projectId}&PRODUCTPERPAGE=${productPerPage}&PAGE=${page}`
            )
            const json = await response.json();
            const data = JSON.parse(json)[0];
            if (data && data.ReturnVal === 1) {
                dispatch(slice.actions.getHotelListLandingSuccess(JSON.parse(data.ReturnData)));
            } else {
                dispatch(slice.actions.getHotelListLandingSuccess(JSON.parse(data.ReturnData)));
                // dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}


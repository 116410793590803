import { createSlice } from '@reduxjs/toolkit';
import { createContext, useEffect, useReducer, useCallback, useMemo } from 'react';
// utils
import axios from '../../utils/axios';
import { ServerConfiguration } from '../../utils/serverConfig';
import { setUser } from '../../auth/utils';

// ----------------------------------------------------------------------

const url = ServerConfiguration.testingServerUrl;

const initialState = {
  isLoading: false,
  error: null,
  country: null,
  states: null,
  paymentMethod: null,
  fpxResponse: null,
  youthEmpowerAction: null,
  cities: null,
  collaborators: null,
};

const slice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET COUNTRY
    getCountrySuccess(state, action) {
      state.isLoading = false;
      state.country = action.payload;
    },

    // GET STATE
    getStateSuccess(state, action) {
      state.isLoading = false;
      state.states = action.payload;
    },

    getPaymentMethodSuccess(state, action) {
      state.isLoading = false;
      state.paymentMethod = action.payload;
    },

    getFPXResponseListSuccess(state, action) {
      state.isLoading = false;
      state.fpxResponse = action.payload;
    },

    addDesignYouthEmpowermentSuccess(state, action) {
      state.isLoading = false;
      state.youthEmpowerAction = action.payload;
    },

    clearYouthEmpowerActionSuccess(state, action) {
      state.isLoading = false;
      state.youthEmpowerAction = null;
    },

    getCitySuccess(state, action) {
      state.isLoading = false;
      state.cities = action.payload;
    },

    getCollaboratorsSuccess(state, action) {
      state.isLoading = false;
      state.collaborators = action.payload;
    },

  },
});

// Reducer
export default slice.reducer;

// Actions
export const { onOpenModal, onCloseModal, selectUser, selectRange, startLoading } = slice.actions;

// ----------------------------------------------------------------------

export const AuthContext = createContext(null);

export function getCountry() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}General_CountryList`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getCountrySuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getState() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}General_ViewState`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getStateSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAllPaymentMethod() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}General_ViewPaymentMethod`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getPaymentMethodSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function getAllFPXResponseList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}General_FPXResponseList`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getFPXResponseListSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addDesignYouthEmpowerment(authorName, authorContact, authorEmail, mediaURL, mediaType) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}YouthEmpower_AddDesign?AUTHORNAME=${authorName}&AUTHORCONTACT=${authorContact}&AUTHOREMAIL=${authorEmail}&MEDIALURL=${mediaURL}&MEDIATYPE=${mediaType}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.addDesignYouthEmpowermentSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearYouthEmpowerAction() {
  return async (dispatch) => {
    dispatch(slice.actions.clearYouthEmpowerActionSuccess());
  };
}

export function getCity() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}General_CityList`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getCitySuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCollaborators() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}General_CollaboratorListing`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getCollaboratorsSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

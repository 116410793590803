//
import _mock from '../_mock';

// ----------------------------------------------------------------------

const NAME = [
  'HARDEX RS900 NEUTRAL SILICONE 300ML -  V-TECH VT218 ',
  'NIETZ N10 MULTI PURPOSE LUBRICANT - ANTI-RUST SPRAY (400ml)',
  'DAEWOO 20V CORDLESS COMBO SET',
  `WESS 6100 4' ANGLE GRINDER 750W`,
  'PNL-Tec MIG280 (IGBT) 1 PHASE INVENTER CO2 GAS SHIELD WELDING MACHINE',
  'PNL-Tec MIG230 (IGBT) 1 PH INVENTER WELDING MACHINE',
  'DAEWOO DEMOLITION HAMMER 1000W - FREE 1PCS HAMMER BULL POINT',
  'MOZTAZ GRASS TRIMMER MONILINE 2.4MM*1LB',
  'KONFOT SB100 SUBMERSIBLE PUMP',
  'KONFOT PS380 AUTO WATER PUMP',
  'NFK DCR-1 CORDLESS BRUSHLESS ANGLE GRINDER C/W 2PCS 4.0MAH BATTERIES AND FAST CHARGER',
  'CAHAYA DIAMOND 4"CONCRTE CUTTING DISC',
  'SOBAR METAL/STAINLESS STEEL 4"CUTTING DISC',
  'RED COTTON WORK GLOVE',
  'PNL-TECH 12V IMPACT DRILL',
  'MOSTAZ 5"CAR POLISHER - MSFF125',
  '3M COMFORT GRIP GLOVE',
  'BLIMAX 12V CORDLESS DRILL',
  'STARWELD MIG WIRE 15KG - 0.8MM 1.0MM 1.5MM',
  '3M 8210 N95 PARTICULATE RESPIRATOR',
  'Konfot SB100 Submersible Water Pump',
  `KING'S SHOES KWD205`,
  `KING'S SHOES KWS200`,
  `KING'S SHOES KWD301`,
  'DAEWOO 20V CORDLESS ROTARY HAMMER - DALRH003',
  'DAEWOO 20V CORDLESS AMOLODORY ANGULAR A BATERIA DE LITIO- DALAG005',
  'DAEWOO 20V CORDLESS LLAVE DE IMPACTO A BATERIA DE LITIO  - DALIW012',
  'DAEWOO 20V CORDLESS  LITHIUM IMPACT DRILL- DALD158H',
  'NFK DCA-1 CORDLESS BRUSHLESS ANGLE GRINDER C/W 2PCS 4.0MAH BATTERIES AND FAST CHARGER',
  'MOSTAZ HIGH PRESSURE CLEANER - MS110ABW',
  'NFK DCI4 IMPACT SCREWDRIVER',
  'NFK DCA1-B2 LITHIUM BRUSHLESS ANGLE GRINDER',
  'NFK DCC1-B2 LITHIUM-ION BRUSHLESS CIRCULAR SAW',
  'NFK DCE1-B1 LITHIUM BRUSHESS ELECTRIC TWO-SPEED DRILL',
  'NFK DCE2-B2 LITHIUM BRUSHLESS IMPACT DRILL',
  'NFK DCH1-B2 LITHIUM BRUSHLESS HAND-PUSH SAW',
  'NFK DCR1-B2 LITHIUM BRUSHLESS ROTARY HAMMER   ',
  'NFK DCR1-B2 LITHIUM BRUSHLESS ROTARY HAMMER',
  'NFK DCR2-B2 LITHIUM-ION BRUSHLESS ROTARY HAMMER',
  'NFK DCW1-B2 LITHIUM BRUSHLESS IMPACT WRENCH',
  'PICASAF 1021 SAFETY SHOE ',
  'SOBAR CUTTING DISC SBCD14 ',
  'TOPFINE RATCHET WRENCH SET ',
  'SENG HENG 3 IN 1 ROTTARY HAMMER',
  'BLIMAX SPIRIT LEVEL 18” ',
  'BLIMAX FIBRE CLAW HAMMER ',
  'LIGHT DUTY CUP BRUSH',
  'BLUE HANDLE KNIFE BRUSH',
  'BLIMAX 3PCS DRILL CHUCK SET-BLM9005 ',
  'KAROKAH YELLOW BOOTS',
  'BLIMAX SPIRIT LEVEL 12” ',
  'LEATHER WELDING GLOVE',
  'BLIMAX HAND RIVETER',
  'BUTANE GAS 4 BOTTLES',
  'BUICK/BIG HOUSE LOCKS 587SS',
  'DAEWOO COMPRESSOR SPRAYER KIT',
  'EARTH CLAMP 500A',
  'PU TUBING AIR HOSE',
  'SAFETY GLASSES ST002D',
  'SAFETY GLASSES (CLEAR)',
  'SAFETY GLASSES SPORTY (BLACK)',
  'SAFETY GLASSES (BLACK)',
];

const CATEGORIES = [
  'Tools and Hardware',
'Tools and Hardware',
'Tools and Hardware',
'Tools and Hardware',
'Tools and Hardware',
'Tools and Hardware',
'Tools and Hardware',
'Tools and Hardware',
'Tools and Hardware',
'Tools and Hardware',
'Tools and Hardware',
'Tools and Hardware',
'Tools and Hardware',
'Tools and Hardware',
'Tools and Hardware',
'Tools and Hardware',
'Tools and Hardware',
'Tools and Hardware',
'Tools and Hardware',
'Tools and Hardware',
'Tools and Hardware',
'Tools and Hardware',
'Tools and Hardware',
'Tools and Hardware',
'Tools and Hardware',
'Tools and Hardware',
'Tools and Hardware',
'Tools and Hardware',
'Tools and Hardware',
'Tools and Hardware',
'Tools and Hardware',
'Tools and Hardware',
'Tools and Hardware',
'Tools and Hardware',
'Tools and Hardware',
'Tools and Hardware',
'Tools and Hardware',
'Tools and Hardware',
'Tools and Hardware',
'Tools and Hardware',
'Tools and Hardware',
'Tools and Hardware',
'Tools and Hardware',
'Tools and Hardware',
'Tools and Hardware',
'Tools and Hardware',
'Tools and Hardware',
'Tools and Hardware',
'Tools and Hardware',
'Tools and Hardware',
'Tools and Hardware',
'Tools and Hardware',
'Tools and Hardware',
'Tools and Hardware',
'Tools and Hardware',
'Tools and Hardware',
'Tools and Hardware',
'Tools and Hardware',
'Tools and Hardware',
'Tools and Hardware',
'Tools and Hardware',
'Tools and Hardware',
];

const IMAGE = [
  'https://cms.myemporia.my/eCommerceCMSImage/products/1280/1280_0_20230516182540.png',
'https://cms.myemporia.my/eCommerceCMSImage/products/1281/1281_0_20230516182621.png',
'https://cms.myemporia.my/eCommerceCMSImage/products/1283/1283_0_20230516164226.png',
'https://cms.myemporia.my/eCommerceCMSImage/products/1284/1284_0_20230516171458.png',
'https://cms.myemporia.my/eCommerceCMSImage/products/1285/1285_0_20230516174743.png',
'https://cms.myemporia.my/eCommerceCMSImage/products/1286/1286_0_20230516182704.png',
'https://cms.myemporia.my/eCommerceCMSImage/products/1287.png',
'https://cms.myemporia.my/eCommerceCMSImage/products/1288.png',
'https://cms.myemporia.my/eCommerceCMSImage/products/1289.png',
'https://cms.myemporia.my/eCommerceCMSImage/products/1290.png',
'https://cms.myemporia.my/eCommerceCMSImage/products/1291/1291_0_20230516181842.png',
'https://cms.myemporia.my/eCommerceCMSImage/products/1292/1292_0_20230516174623.png',
'https://cms.myemporia.my/eCommerceCMSImage/products/1293.png',
'https://cms.myemporia.my/eCommerceCMSImage/products/1294/1294_0_20230516174707.png',
'https://cms.myemporia.my/eCommerceCMSImage/products/1295/1295_0_20230516182757.png',
'https://cms.myemporia.my/eCommerceCMSImage/products/1296.png',
'https://cms.myemporia.my/eCommerceCMSImage/products/1297/1297_0_20230516173233.png',
'https://cms.myemporia.my/eCommerceCMSImage/products/1298/1298_0_20230516174551.png',
'https://cms.myemporia.my/eCommerceCMSImage/products/1299.png',
'https://cms.myemporia.my/eCommerceCMSImage/products/1300/1300_0_20230516170506.png',
'NULL',
'https://cms.myemporia.my/eCommerceCMSImage/products/1310.png',
'https://cms.myemporia.my/eCommerceCMSImage/products/1311.png',
'https://cms.myemporia.my/eCommerceCMSImage/products/1312.png',
'https://cms.myemporia.my/eCommerceCMSImage/products/1313.png',
'https://cms.myemporia.my/eCommerceCMSImage/products/1314.png',
'https://cms.myemporia.my/eCommerceCMSImage/products/1315.png',
'https://cms.myemporia.my/eCommerceCMSImage/products/1316.png',
'https://cms.myemporia.my/eCommerceCMSImage/products/1317.png',
'https://cms.myemporia.my/eCommerceCMSImage/products/1318.png',
'https://cms.myemporia.my/eCommerceCMSImage/products/1319.png',
'https://cms.myemporia.my/eCommerceCMSImage/products/1320.png',
'https://cms.myemporia.my/eCommerceCMSImage/products/1321.png',
'https://cms.myemporia.my/eCommerceCMSImage/products/1322.png',
'https://cms.myemporia.my/eCommerceCMSImage/products/1323.png',
'https://cms.myemporia.my/eCommerceCMSImage/products/1324.png',
'https://cms.myemporia.my/eCommerceCMSImage/products/1325.png',
'NULL',
'https://cms.myemporia.my/eCommerceCMSImage/products/1327.png',
'https://cms.myemporia.my/eCommerceCMSImage/products/1328.png',
'https://cms.myemporia.my/eCommerceCMSImage/products/1329.png',
'https://cms.myemporia.my/eCommerceCMSImage/products/1330.png',
'https://cms.myemporia.my/eCommerceCMSImage/products/1331.png',
'https://cms.myemporia.my/eCommerceCMSImage/products/1332.png',
'https://cms.myemporia.my/eCommerceCMSImage/products/1333.png',
'https://cms.myemporia.my/eCommerceCMSImage/products/1334.png',
'https://cms.myemporia.my/eCommerceCMSImage/products/1335.png',
'https://cms.myemporia.my/eCommerceCMSImage/products/1336.png',
'https://cms.myemporia.my/eCommerceCMSImage/products/1337.png',
'https://cms.myemporia.my/eCommerceCMSImage/products/1338.png',
'https://cms.myemporia.my/eCommerceCMSImage/products/1339.png',
'https://cms.myemporia.my/eCommerceCMSImage/products/1340.png',
'https://cms.myemporia.my/eCommerceCMSImage/products/1341.png',
'https://cms.myemporia.my/eCommerceCMSImage/products/1342.png',
'https://cms.myemporia.my/eCommerceCMSImage/products/1343.png',
'https://cms.myemporia.my/eCommerceCMSImage/products/1344.png',
'https://cms.myemporia.my/eCommerceCMSImage/products/1345.png',
'https://cms.myemporia.my/eCommerceCMSImage/products/1346.png',
'https://cms.myemporia.my/eCommerceCMSImage/products/1347.png',
'https://cms.myemporia.my/eCommerceCMSImage/products/1348.png',
'https://cms.myemporia.my/eCommerceCMSImage/products/1349.png',
'https://cms.myemporia.my/eCommerceCMSImage/products/1350.png',
]

const PRICE = [
  '9.90',
'10.00',
'829.00',
'89.00',
'639.00',
'399.00',
'429.00',
'13.90',
'149.90',
'399.00',
'599.00',
'19.00',
'40.00',
'9.00',
'169.00',
'89.00',
'11.90',
'99.00',
'129.00',
'50.00',
'50.00',
'212.00',
'135.00',
'176.00',
'0.00',
'0.00',
'0.00',
'0.00',
'0.00',
'179.00',
'588.00',
'0.00',
'696.00',
'439.00',
'735.00',
'746.00',
'0.00',
'0.00',
'0.00',
'0.00',
'0.00',
'0.00',
'0.00',
'0.00',
'23.90',
'19.90',
'5.90',
'9.90',
'14.90',
'25.90',
'19.90',
'11.90',
'21.90',
'19.90',
'9.90',
'79.90',
'19.90',
'0.00',
'30.00',
'7.90',
'0.00',
'0.00',
]

const ID = [
  '1280',
'1281',
'1283',
'1284',
'1285',
'1286',
'1287',
'1288',
'1289',
'1290',
'1291',
'1292',
'1293',
'1294',
'1295',
'1296',
'1297',
'1298',
'1299',
'1300',
'1309',
'1310',
'1311',
'1312',
'1313',
'1314',
'1315',
'1316',
'1317',
'1318',
'1319',
'1320',
'1321',
'1322',
'1323',
'1324',
'1325',
'1326',
'1327',
'1328',
'1329',
'1330',
'1331',
'1332',
'1333',
'1334',
'1335',
'1336',
'1337',
'1338',
'1339',
'1340',
'1341',
'1342',
'1343',
'1344',
'1345',
'1346',
'1347',
'1348',
'1349',
'1350',
]

const SELECTEDID = [
  '1328',
  '1318',
  '1',
]

const SELECTEDNAME = [
  'NFK DCW1-B2 LITHIUM BRUSHLESS IMPACT WRENCH',
  'MOSTAZ HIGH PRESSURE WATER JET - MS110ABW',
  'Apple Watch'
]

const SELECTEDCAPTION = [
  'NFK DCW1-B2 LITHIUM BRUSHLESS IMPACT WRENCH BEST OF THE BEST, TOP 1 in Worldwide',
  'Experience a new level of cleanliness with the MOSTAZ HIGH PRESSURE WATER JET - MS110ABW. Its robust power and superior engineering deliver unmatched efficiency, making cleaning chores faster, easier, and more effective.',
  'The Apple Watch is a high-tech wearable device designed by Apple Inc. that serves as a multipurpose companion for your iPhone. Providing a blend of fashion and functionality, the Apple Watch delivers an array of features that are aimed at enhancing daily life and promoting health and wellness.'
]

const SELECTEDIMAGE = [
  '/assets/images/product/Product123.png',
  '/assets/images/product/P.png',
  '/assets/images/product/product_1.png'
  
]

const DESCRIPTION = `
<p>Aenean viverra rhoncus pede. Etiam feugiat lorem non metus. Quisque malesuada placerat nisl.</p>

<ul>
  <li> Updated with a more matte texture, perfect for casual styling. </li>
  <li> Durable water-repellent coating. </li>
  <li> dsdsds </li>
  <li> dsdsds </li>
  <li> Anti-static lining. </li>
</ul>

<p>Living in today’s metropolitan world of cellular phones, mobile computers and other high-tech gadgets is not just hectic but very impersonal. We make money and then invest our time and effort in making more money..</p>
`;

// ----------------------------------------------------------------------

export const _productsTable = [...Array(12)].map((_, index) => ({
  id: _mock.id(index),
  orderId: `#011120${index + 1}`,
  item: NAME[index],
  deliveryDate: _mock.time(index),
  price: _mock.number.price(index),
  status: ['Completed', 'To Process', 'Cancelled', 'Return'][index] || 'Completed',
}));

// ----------------------------------------------------------------------

export const _productsCarousel = [...Array(4)].map((_, index) => ({
  id: SELECTEDID[index],
  title: SELECTEDNAME[index],
  caption: SELECTEDCAPTION[index],
  coverImg: SELECTEDIMAGE[index],
  label: 'Opening Sale Discount 50%',
}));

// ----------------------------------------------------------------------

export const _productsCompare = [
  'Apple iPhone 12 Pro',
  'Apple iPhone 13 Pro',
  'Apple iPhone 14 Pro',
].map((name, index) => ({
  id: _mock.id(index),
  name,
  price: _mock.number.price(index),
  rating: _mock.number.rating(index),
  coverImg: _mock.image.product(4),
  details: (index === 0 && [
    'Super Retina XDR (OLED)',
    'Up to 29 hours video playback',
    'A14 Bionic',
    'True Tone',
    'IP68',
    '2017',
  ]) || ['Super Retina XDR (OLED)', '', 'A14 Bionic', '', 'IP68', '2017'],
}));

// ----------------------------------------------------------------------

export const _products = [...Array(24)].map((_, index) => ({
  id: ID[index],
  name: NAME[index],
  caption: _mock.text.description(index),
  description: DESCRIPTION,
  coverImg: IMAGE[index],
  review: index * 2 + 40,
  category: CATEGORIES[index],
  sold: index * 2 + 40,
  inStock: 100,
  rating: _mock.number.rating(index),
  label: ['sale', 'new', 'sale', 'sale'][index] || '',
  price: PRICE[index],
  priceSale:
    [
      _mock.number.price(1),
      _mock.number.price(2),
      _mock.number.price(3),
      _mock.number.price(4),
      _mock.number.price(5),
    ][index] || 0,
  images: [
    _mock.image.product(1),
    _mock.image.product(2),
    _mock.image.product(3),
    _mock.image.product(4),
    _mock.image.product(5),
    _mock.image.product(6),
    _mock.image.product(7),
    _mock.image.product(8),
  ],
}));

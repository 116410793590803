import { createSlice } from '@reduxjs/toolkit';
import { createContext, useEffect, useReducer, useCallback, useMemo } from 'react';
// utils
import axios from '../../utils/axios';
import { ServerConfiguration } from '../../utils/serverConfig';
import { setUser } from '../../auth/utils';

// ----------------------------------------------------------------------

const url = ServerConfiguration.testingServerUrl;
const PROJECTID = 2;

const initialState = {
  isLoading: false,
  error: null,
  // user: null,
  address: [],
  registerAction: null,
  forgetPasswordAction: null,
  forgetPasswordVerified: [],
  userFeedback: null,

};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCT
    loginSuccess(state, action) {
      state.isLoading = false;
      state.user = action.payload;
    },

    getUserAddressSuccess(state, action) {
      state.isLoading = false;
      state.address = action.payload;
    },

    addUserFeedbackSuccess(state, action) {
      state.isLoading = false;
      state.userFeedback = action.payload;
    },

    clearUserFeedbackSuccess(state, action) {
      state.isLoading = false;
      state.userFeedback = null;
    },
    
    OTPVerified(state, action) {
      state.isLoading = false;
      state.registerAction = action.payload;
    },

    forgetPasswordOTPsent(state, action) {
      state.isLoading = false;
      state.forgetPasswordAction = action.payload;
    },

    forgetPasswordVerifyOTP(state, action) {
      state.isLoading = false;
      state.forgetPasswordVerified = action.payload;
    },

    unsubscribeOTPActionSuccess(state, action) {
      state.isLoading = false;
      state.forgetPasswordAction = null;
      state.forgetPasswordVerified = null;
      state.registerAction = null;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { onOpenModal, onCloseModal, selectUser, selectRange, startLoading } = slice.actions;

// ----------------------------------------------------------------------

export const AuthContext = createContext(null);

export function verifyOtp(UserID, Email, OTP, TOKEN, TYPE) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}User_Register_VerifyOTP?USERID=${UserID}&USEREMAIL=${Email}&OTP=${OTP}&TOKEN=${TOKEN}&TYPE=${TYPE}`
      )
    
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        setUser(data.ReturnData)
        dispatch(slice.actions.OTPVerified(JSON.parse(data.ReturnData)[0]));
      } else {
        dispatch(slice.actions.OTPVerified(data.ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function forgetPasswordOTP(UserID, TYPE, UpdatedValue) {
  // TYPE = "FORGETPASSWORD"
  // VALIDATIONFIELD= '-'
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        // `${url}User_SentOTPVerification?USERID=${UserID}&TYPE=${TYPE}&VALIDATIONFIELD=${UpdatedValue}`
        `${url}User_SentOTPVerificationWithProjectID?USERID=${UserID}&TYPE=${TYPE}&VALIDATIONFIELD=${UpdatedValue}&PROJECTID=${PROJECTID}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        setUser(data.ReturnData)
        dispatch(slice.actions.forgetPasswordOTPsent(JSON.parse(data.ReturnData)[0]));

      } else {
        dispatch(slice.actions.forgetPasswordOTPsent(data.ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function forgetPasswordChangePassword(USEREMAIL, OTP, PASSWORD) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}User_ForgetPassword_VerifyOTPWithProjectID?USEREMAIL=${USEREMAIL}&OTP=${OTP}&PASSWORD=${PASSWORD}&PROJECTID=${PROJECTID}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        setUser(data.ReturnData)
        dispatch(slice.actions.forgetPasswordVerifyOTP(JSON.parse(data.ReturnData)[0]));

      } else {
        dispatch(slice.actions.forgetPasswordVerifyOTP(data.ReturnMsg));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function unsubscribeOTPAction() {
  return async (dispatch) => {
      dispatch(slice.actions.unsubscribeOTPActionSuccess());
  };
}

export function getUserAddress(userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}User_ViewAddressBook?USERID=${userId}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getUserAddressSuccess(JSON.parse(data.ReturnData)));

      } else {
        dispatch(slice.actions.getUserAddressSuccess(JSON.parse(data.ReturnData)));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addUserFeedback(subject, body, name, email) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}User_AddUserFeedback?SUBJECT=${subject}&BODY=${body}&NAME=${name}&EMAIL=${email}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        setUser(data.ReturnData)
        dispatch(slice.actions.addUserFeedbackSuccess(JSON.parse(data.ReturnData)[0]));

      } else {
        dispatch(slice.actions.addUserFeedbackSuccess(data.ReturnMsg));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearUserFeedback() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {   
      dispatch(slice.actions.clearUserFeedbackSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

import { useState, useEffect } from 'react';
// @mui
import { Fab } from '@mui/material';
// components
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export default function ScrollToTopButton() {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isShow = window.scrollY > 400;
      setShow(isShow);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  if (!show) {
    return null;
  }

  return (
    <Fab
      size="small"
      color="primary"
      aria-label="scroll back to top"
      sx={{
        position: 'fixed',
        bottom: 16,
        right: 16,
        zIndex: (theme) => theme.zIndex.speedDial,
        color: 'common.white',
      }}
      onClick={handleClick}
    >
      <Iconify icon="eva:arrow-upward-outline" width={20} height={20} />
    </Fab>
  );
}
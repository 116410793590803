import PropTypes from 'prop-types';
// @mui
import { Stack, Button, Typography } from '@mui/material';
//
import NavList from './NavList';

// ----------------------------------------------------------------------

export default function YouthEmpowerNavDesktop({ data, sx }) {
  const handleClickScroll = (path) => {
    const element = document.getElementById(path);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Stack
      component="nav"
      direction="row"
      spacing={6}
      sx={{
        ml: 6,
        height: 1,
        ...sx,
      }}
    >
      {data.map((link) => (
        <Button
          color='secondary'
          variant='text'
          key={link.title}
          onClick={() => handleClickScroll(link.path)}
        >
          {link?.child ? (
            <NavList key={link.title} item={link} />
          ) : (
            <Typography variant='body2'>
              {link.title}
            </Typography>
          )}
        </Button>
      ))}
    </Stack>
  );
}

YouthEmpowerNavDesktop.propTypes = {
  data: PropTypes.array,
  sx: PropTypes.object,
};

import { useLocation, Link } from 'react-router-dom';
// @mui
import { alpha, styled } from '@mui/material/styles';
import Masonry from '@mui/lab/Masonry';
import {
  Stack,
  Button,
  Divider,
  Container,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
  Card,
  Unstable_Grid2 as Grid,
  CardContent,
} from '@mui/material';
// hooks
import useResponsive from 'src/hooks/useResponsive';
// _mock
import { _socials } from 'src/_mock';
// components
import Logo from 'src/components/logo';
import Iconify from 'src/components/iconify';
import ScrollToTopButton from 'src/layouts/components/ScrollToTopButton';
import { StyledLink } from './styles';
//
import { pageLinks, navConfig } from '../nav/config-navigation';
import ListDesktop from './ListDesktop';
import ListMobile from './ListMobile';
// assets
import CityLink from '../../../assets/citylink.png';
import VISA from '../../../assets/Visa-Logo.png';
import MASTER from '../../../assets/Mastercard-logo.svg.png';
import FPX from '../../../assets/fpx-logo.png';
import cimb from '../../../assets/cimb.png';
import hlb from '../../../assets/hlb.png';
import maybank from '../../../assets/maybank.png';
import pbb from '../../../assets/publicbank.png';
import rhb from '../../../assets/rhb.png';
import ocbc from '../../../assets/ocbc.png';
import uob from '../../../assets/uob.png';
import bankrakyat from '../../../assets/bankrakyat.png';
import bankislam from '../../../assets/bankislam.png';
import affinbank from '../../../assets/affinbank.png';
import hsbc from '../../../assets/hsbc.png';
import ambank from '../../../assets/ambank.png';
import citibank from '../../../assets/citibank.png';
// ----------------------------------------------------------------------

const StyledAppStoreButton = styled(Button)(({ theme }) => ({
  flexShrink: 0,
  padding: '5px 12px',
  margin: theme.spacing(1),
  color: theme.palette.common.white,
  border: `solid 1px ${alpha(theme.palette.common.black, 0.24)}`,
  background: `linear-gradient(180deg, ${theme.palette.grey[900]} 0%, ${theme.palette.common.black} 100%)`,
  '& .MuiButton-startIcon': {
    marginLeft: 0,
  },
}));

// ----------------------------------------------------------------------

export default function Footer() {
  const isMdUp = useResponsive('up', 'md');

  const { pathname, location } = useLocation();

  const mobileList = navConfig.find((i) => i.title === 'Pages')?.children || [];

  const desktopList = pageLinks.sort((listA, listB) => Number(listA.order) - Number(listB.order));

  const renderLists = isMdUp ? desktopList : mobileList;

  const isHome = pathname === '/';

  const simpleFooter = (
    <Container sx={{ py: 8, textAlign: 'center' }}>
      <Logo single />

      <Typography variant="caption" component="div" sx={{ color: 'text.secondary' }}>
        © 2023. All rights reserved
      </Typography>
    </Container>
  );

  const informationLinks = [
    { title: "About MyEmporia", url: "/site/about-us" },
    // { title: "Afﬁliate Program", url: "/site/affiliate" },
    // { title: "Careers", url: "/site/careers" },
    { title: "Terms & Conditions", url: "/site/terms" },
    { title: "Privacy Policy", url: "/site/policy" },
    // { title: "Campaign Terms & Conditions", url: "/site/campaigncondition" },
    { title: "Partnership", url: "/site/partnership" },
    { title: "Contact Us", url: "/site/contact-us" },
    // { title: "Form", url: "/site/Form" },
  ];

  const otherInformationLinks = [
    { title: "Help Center", url: "/site/faq" },
    { title: "How to Buy", url: "/site/howtobuy" },
    { title: "Shipping & Delivery", url: "/site/shippingdelivery" },
    { title: "Youth Empowerment", url: "/youthEmpower" },    
    // { title: "Food Directory", url: "/directory/landing" },
    // { title: "International Product Policy", url: "" },
    // { title: "How to Return", url: "/site/howtoreturn" },
    // { title: "Question?", url: "/site/faq" },
    // { title: "Contact Us", url: "/site/contact-us" },
  ];

  const payment = [
    { paymentID: 1, paymentImg: VISA },
    { paymentID: 2, paymentImg: MASTER },
    { paymentID: 3, paymentImg: FPX },
    { paymentID: 4, paymentImg: rhb },
    { paymentID: 5, paymentImg: affinbank },
    { paymentID: 6, paymentImg: ambank },
    { paymentID: 7, paymentImg: bankrakyat },
    { paymentID: 8, paymentImg: bankislam },
    { paymentID: 9, paymentImg: citibank },
    { paymentID: 10, paymentImg: cimb },
    { paymentID: 11, paymentImg: hlb },
    { paymentID: 12, paymentImg: hsbc },
    { paymentID: 13, paymentImg: maybank },
    { paymentID: 14, paymentImg: pbb },
    { paymentID: 15, paymentImg: ocbc },
    { paymentID: 16, paymentImg: uob },
  ]

  const handleNavigation = ()=>{
    window.location.href = "booklahapp://lightBuyEventTicketConfirmPaymentScreen"
  }

  const handleNavigation2 = ()=>{
    window.location.href = "https://booklahapp.com://lightBuyEventTicketConfirmPaymentScreen"
  }
  

  const mainFooter = (
    <>
      <ScrollToTopButton />

      <Divider />

      <Container
        sx={{
          overflow: 'hidden',
          backgroundColor: 'primary.lighter'
        }}
      >
        <Grid container justifyContent={{ md: 'space-between' }}>
          <Grid xs={12} md={6}>
            <Stack alignItems="center">
              <Logo single sx={{ width: "100px" }} />
            </Stack>

            <Stack >
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                MyEmporia is a virtual shopping mall where you can get your goods with QUALITY GUARANTEE and 99.99% Satisfaction Guarantee.
              </Typography>
              {/* <Stack spacing={1}>
                <Typography variant="h6">Let’s stay in touch</Typography>
                <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                  Subscribe to our newsletter to receive latest articles to your inbox weekly.
                </Typography>
              </Stack>

              <TextField
                fullWidth
                hiddenLabel
                placeholder="Email address"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button variant="contained" color="inherit" size="large">
                        Subscribe
                      </Button>
                    </InputAdornment>
                  ),
                  sx: { pr: 0.5 },
                }}
              /> */}
            </Stack>
          </Grid>

          <Grid xs={12} md={6} mt={5}>
            <Masonry columns={3} spacing={2} defaultColumns={3} defaultSpacing={2}>
              <Stack spacing={1.5} alignItems="flex-start">
                <Typography variant="subtitle2">MyEmporia</Typography>

                {informationLinks?.map((link, index) => (
                  <StyledLink key={index} to={link.url}>
                    {link.title}
                  </StyledLink>
                ))}
              </Stack>
              <Stack spacing={1.5} alignItems="flex-start">
                <Typography variant="subtitle2">Other Information</Typography>

                {otherInformationLinks?.map((link, index) => (
                  <StyledLink key={index} to={link.url}>
                    {link.title}
                  </StyledLink>
                ))}
              </Stack>
              <Stack spacing={2} alignItems="flex-start">
                <Typography variant="subtitle2">Social</Typography>
                <Stack direction="row" alignItems="center">
                  {_socials.map((social) => (
                    <IconButton key={social.value} color="primary" onClick={() => { window.open(social.URL, '_blank') }}>
                      <Iconify icon={social.icon} />
                    </IconButton>
                  ))}
                </Stack>
              </Stack>
            </Masonry>
          </Grid>

          <Grid xs={12} md={12} mt={3}>
            <Masonry columns={{ sm: 1, md: 2 }} spacing={2} defaultColumns={2} defaultSpacing={2}>
              <Stack spacing={1.5} >
                <Typography variant='caption' style={{ fontWeight: "700", color: "#2b535e" }}>LOGISTICS</Typography>
                <img width="30%" src={CityLink} alt='citylink' style={{ padding: "0.5vw" }} />
              </Stack>
              <Stack spacing={1.5} >
                <Typography variant='caption' style={{ fontWeight: "700", color: "#2b535e" }}>PAYMENT</Typography>
                <Stack
                  spacing={0.5}
                  display="grid"
                  gridTemplateColumns={{ xs: 'repeat(3, 1fr)', sm: 'repeat(5, 1fr)', md: 'repeat(5, 1fr)' }}
                >
                  {
                    payment.map((x, index) => (
                      <CardContent style={{ padding: "0px", display: "flex", justifyContent: "center", alignItems: "center" }} key={`Payment_${index}`}>
                        <img alt="payment" key={x.paymentID} width="40%" src={x.paymentImg} />
                      </CardContent>
                    ))
                  }
                </Stack>
              </Stack>
            </Masonry>
          </Grid>
        </Grid>
      </Container>

      <Divider />

      <Container>
        <Stack
          spacing={2.5}
          direction={{ xs: 'column', md: 'row' }}
          justifyContent="space-between"
          sx={{ py: 3, textAlign: 'center' }}
        >
          <Typography variant="caption" sx={{ color: 'text.secondary' }}>
            © 2023 MyEmporia Sdn. Bhd. (1422486-P)
          </Typography>
        </Stack>


        {/* {(navigator.userAgent.toLowerCase().indexOf("mobile") !== -1) && */}
          {/* <Button variant="caption" sx={{ color: 'text.secondary' }} rel="alternate" onClick={()=> handleNavigation()} >
            Click Here 1
          </Button>

          
          <Button variant="caption" sx={{ color: 'text.secondary' }} rel="alternate" onClick={()=> handleNavigation2()} >
            Click Here 2
          </Button> */}
      </Container>
    </>
  );

  return <footer> {mainFooter}</footer>;
  // return <footer>{isHome ? simpleFooter : mainFooter}</footer>;

}

// ----------------------------------------------------------------------

function AppStoreButton({ ...other }) {
  return (
    <Stack direction="row" flexWrap="wrap" {...other}>
      <StyledAppStoreButton startIcon={<Iconify icon="ri:apple-fill" width={28} />}>
        <Stack alignItems="flex-start">
          <Typography variant="caption" sx={{ opacity: 0.72 }}>
            Download on the
          </Typography>

          <Typography variant="h6" sx={{ mt: -0.5 }}>
            Apple Store
          </Typography>
        </Stack>
      </StyledAppStoreButton>

      <StyledAppStoreButton startIcon={<Iconify icon="logos:google-play-icon" width={28} />}>
        <Stack alignItems="flex-start">
          <Typography variant="caption" sx={{ opacity: 0.72 }}>
            Download from
          </Typography>
          <Typography variant="h6" sx={{ mt: -0.5 }}>
            Google Play
          </Typography>
        </Stack>
      </StyledAppStoreButton>
    </Stack>
  );
}

import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useCallback, useMemo } from 'react';

import { ServerConfiguration } from '../utils/serverConfig';
import localStorageAvailable from '../utils/localStorageAvailable';
//
import { isValidToken, setUser, setRole, clearCookies } from './utils';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const PROJECTID = ServerConfiguration.PROJECTID;
const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
};


const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }

  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext(null);

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export function AuthProvider({ children }) {
  const url = ServerConfiguration.testingServerUrl;
  const [state, dispatch] = useReducer(reducer, initialState);
  const storageAvailable = localStorageAvailable();

  const initialize = useCallback(async () => {
    try {
      const accessToken = storageAvailable ? localStorage.getItem('user') : null;
      if (accessToken) {
        dispatch({
          type: 'INITIAL',
          payload: {
            isAuthenticated: true,
            user: JSON.parse(accessToken)[0],
          },
        });
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, [storageAvailable]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (email, password, domain) => {
    const response = await fetch(
      // `${url}User_Login?username=${email}&password=${password}&ProjectDomainName=${domain}`
      `${url}User_LoginBackdoor?username=${email}`
    )
    const json = await response.json();
    const data = JSON.parse(json)[0];
    if (data && data.ReturnVal === 1) {
      setUser(data.ReturnData);
      dispatch({
        type: 'LOGIN',
        payload: {
          user: JSON.parse(data.ReturnData)[0],
        },
      });
      return true;
    }
    return false;
  }, [url]);

  const loginBuyNow = useCallback(async (userID, projectID) => {
    const response = await fetch(
      `${url}User_LoginBuyNow?USERID=${userID}&PROJECTID=${PROJECTID}`
    )
    const json = await response.json();
    const data = JSON.parse(json)[0];
    if (data && data.ReturnVal === 1) {
      setUser(data.ReturnData);
      dispatch({
        type: 'LOGIN',
        payload: {
          user: JSON.parse(data.ReturnData)[0],
        },
      });
      return true;
    }
    return false;
  }, [url]);

  const loginGoogleFB = useCallback(async (email, id, token, type, firstName, lastName) => {
    const response = await fetch(
      `${url}User_Login_GoogleFB?USEREMAIL=${email}&PROJECTID=${PROJECTID}&TOKEN=${token}&TYPE=${type}&FIRSTNAME=${firstName}&LASTNAME=${lastName}`
    )
    const json = await response.json();
    const data = JSON.parse(json)[0];
    if (data && data.ReturnVal === 1) {
      setUser(data.ReturnData);
      dispatch({
        type: 'LOGIN',
        payload: {
          user: JSON.parse(data.ReturnData)[0],
        },
      });
      return true;
    }
    return false;
  }, [url]);

  const register = useCallback(async (email, password) => {
    try {
      const response = await fetch(
        `${url}User_Register_WithOTP?USEREMAIL=${email}&PASSWORD=${password}&PROJECTID=${PROJECTID}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        let returnData = true
        dispatch({
          type: 'REGISTER',
          payload: {
            user: JSON.parse(data.ReturnData)[0],
          },
        });
        if (data.ReturnMsg === "The OTP had Sent") {
          setUser(data.ReturnData);
        }
        else
          returnData = data.ReturnSqlError

        return returnData;

      }

      return data.ReturnSqlError;
      // return false;
    } catch (e) {
      console.log("register", e)
      return false

    }
  }, [url]);

  // LOGOUT
  const logout = useCallback(async (id) => {
    const response = await fetch(
      `${url}Audit_AddUserLogout?USERID=${id}`
    )
    setUser(null);
    clearCookies();
    dispatch({
      type: 'LOGOUT',
      payload: {
        user: null,
      },
    });
    return true;

  }, [url]);

  // const logout = useCallback(() => {
  //   // setSession(null);
  //   setUser(null);
  //   dispatch({
  //     type: 'LOGOUT',
  //   });
  // }, []);

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      method: 'jwt',
      login,
      register,
      logout,
      loginGoogleFB,
      loginBuyNow
    }),
    [state.isAuthenticated, state.isInitialized, state.user, login, logout, register, loginGoogleFB, loginBuyNow]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

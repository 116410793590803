import { add } from 'date-fns';
//
import _mock from '../_mock';

// ----------------------------------------------------------------------

const CONTINENTS = [
  'Asia',
  'Europe',
  'Africa',
  'Australia',
  'North America',
  'South America',
  'Antarctica',
  'Asia',
  'Europe',
  'Africa',
  'Australia',
  'North America',
  'South America',
  'Antarctica',
  'South America',
  'Antarctica',
];

export const _tours = [...Array(12)].map((_, index) => ({
  id: _mock.id(index),
  coverImg: _mock.image.travel(index),
  heroImg: [
    '/assets/images/travel/travel_post_hero.jpg',
    '/assets/images/travel/travel_post_01.jpg',
    '/assets/images/travel/travel_post_02.jpg',
    '/assets/images/travel/travel_post_03.jpg',
    '/assets/images/travel/travel_post_04.jpg',
  ][index],
  slug: _mock.text.tourName(index),
  createdAt: _mock.time(index),
  availableStart: add(new Date(), { months: 2 }),
  availableEnd: add(new Date(), { months: 4 }),
  location: _mock.address.country(index),
  continent: CONTINENTS[index],
  duration: '3 days 2 nights',
  price: (index % 2 && 159.99) || 269.99,
  priceSale: (index === 2 && 89.99) || (index === 5 && 69.99) || 0,
  reviews: 345,
  favorited: index === 2 || index === 4 || false,
  ratings: 3.5 + index / 10,
  tourGuide: {
    name: _mock.name.fullName(index),
    role: _mock.role(index),
    picture: _mock.image.avatar(index),
    phoneNumber: _mock.phoneNumber(index),
    quotes: 'Member since Mar 15, 2021',
    verified: true,
    ratings: 5,
    reviews: 345,
    about:
      'Integer tincidunt. Nullam dictum felis eu pede mollis pretium. Maecenas ullamcorper, dui et placerat feugiat, eros pede varius nisi, condimentum viverra felis nunc et lorem.',
    shareLinks: _mock.shareLinks,
  },
  includes: [
    { label: 'Audio guide', enabled: true },
    { label: 'Entrance fees', enabled: true },
    { label: 'Food and drinks', enabled: true },
    { label: 'Gratuities', enabled: true },
    { label: 'Lunch', enabled: true },
    { label: 'Pick-up and drop off', enabled: false },
    { label: 'Private tour', enabled: false },
    { label: 'Professional guide', enabled: false },
    { label: 'Special activities', enabled: false },
    { label: 'Transport by air-conditioned', enabled: false },
  ],
  languages: ['Russian', 'Spanish'],
  tags: ['Lamp', 'A man', 'Human', 'Lantern', 'Festival'],
  gallery: [...Array(6)].map((__, itemIndex) => _mock.image.travel(itemIndex + 2)),
  description: _mock.text.description(index),
  highlights: [...Array(6)].map((__, itemIndex) => _mock.text.sentence(itemIndex)),
  program: [...Array(3)].map((__, itemIndex) => ({
    label: `Day ${itemIndex + 1}`,
    text: _mock.text.description(itemIndex),
  })),
  shareLinks: _mock.shareLinks,
}));

export const _hotels = [...Array(12)].map((_, index) => ({
  id: _mock.id(index),
  coverImg: [
    'https://cf.bstatic.com/xdata/images/hotel/max1024x768/39241664.jpg?k=be9f0b972a3e7505840ab8797d3f75b50caaa21c000d376c8c8b567cfb008253&o=&hp=1',
    'https://dynamic-media-cdn.tripadvisor.com/media/photo-o/0a/ab/9f/3f/the-waterfront-hotel.jpg?w=700&h=-1&s=1',
    'https://cf.bstatic.com/xdata/images/hotel/max1024x768/411657603.jpg?k=9882953ce7929c8efdda6bc19faee24aa3e05432070336cab33d90fd1bc8b34f&o=&hp=1',
    'https://ik.imgkit.net/3vlqs5axxjf/external/http://media.iceportal.com/59838/photos/74528493_XL.jpg?tr=w-1200%2Cfo-auto',
    'https://q-xx.bstatic.com/xdata/images/hotel/max1024x768/324659983.jpg?k=96eb8dab2eccf9b5edd1c2483c210a75f032e0d7ed0c76fc243ca3656b6440a1&o=',
  ][index],
  heroImg: [
    'https://cf.bstatic.com/xdata/images/hotel/max1024x768/39241664.jpg?k=be9f0b972a3e7505840ab8797d3f75b50caaa21c000d376c8c8b567cfb008253&o=&hp=1',
    'https://dynamic-media-cdn.tripadvisor.com/media/photo-o/0a/ab/9f/3f/the-waterfront-hotel.jpg?w=700&h=-1&s=1',
    'https://cf.bstatic.com/xdata/images/hotel/max1024x768/411657603.jpg?k=9882953ce7929c8efdda6bc19faee24aa3e05432070336cab33d90fd1bc8b34f&o=&hp=1',
    'https://ik.imgkit.net/3vlqs5axxjf/external/http://media.iceportal.com/59838/photos/74528493_XL.jpg?tr=w-1200%2Cfo-auto',
    'https://q-xx.bstatic.com/xdata/images/hotel/max1024x768/324659983.jpg?k=96eb8dab2eccf9b5edd1c2483c210a75f032e0d7ed0c76fc243ca3656b6440a1&o=',
  ][index],
  slug: ["Pullman Hotel", "The Waterfront Hotel", "Riverside Majestic Hotel", "Hilton Hotel", "Grand Margherita Hotel",][index],
  createdAt: _mock.time(index),
  availableStart: add(new Date(), { months: 2 }),
  availableEnd: add(new Date(), { months: 4 }),
  location: ["Pullman Kuching", "The Waterfront Kuching", "Riverside Majestic Kuching", "Hilton Kuching", "Grand Margherita Kuching",][index],
  continent: ["Malaysia", "Malaysia", "Malaysia", "Malaysia", "Malaysia",][index],
  duration: '3 days 2 nights',
  price: (index % 2 && 159.99) || 269.99,
  priceSale: (index === 2 && 89.99) || (index === 5 && 69.99) || 0,
  reviews: 345,
  favorited: index === 2 || index === 4 || false,
  ratings: 3.5 + index / 10,
  tourGuide: {
    name: _mock.name.fullName(index),
    role: _mock.role(index),
    picture: _mock.image.avatar(index),
    phoneNumber: _mock.phoneNumber(index),
    quotes: 'Member since Mar 15, 2021',
    verified: true,
    ratings: 5,
    reviews: 345,
    about:
      'Integer tincidunt. Nullam dictum felis eu pede mollis pretium. Maecenas ullamcorper, dui et placerat feugiat, eros pede varius nisi, condimentum viverra felis nunc et lorem.',
    shareLinks: _mock.shareLinks,
  },
  includes: [
    { label: 'Audio guide', enabled: true },
    { label: 'Entrance fees', enabled: true },
    { label: 'Food and drinks', enabled: true },
    { label: 'Gratuities', enabled: true },
    { label: 'Lunch', enabled: true },
    { label: 'Pick-up and drop off', enabled: false },
    { label: 'Private tour', enabled: false },
    { label: 'Professional guide', enabled: false },
    { label: 'Special activities', enabled: false },
    { label: 'Transport by air-conditioned', enabled: false },
  ],
  languages: ['Russian', 'Spanish'],
  tags: ['Lamp', 'A man', 'Human', 'Lantern', 'Festival'],
  gallery: [...Array(6)].map((__, itemIndex) => _mock.image.travel(itemIndex + 2)),
  description: _mock.text.description(index),
  highlights: [...Array(6)].map((__, itemIndex) => _mock.text.sentence(itemIndex)),
  program: [...Array(3)].map((__, itemIndex) => ({
    label: `Day ${itemIndex + 1}`,
    text: _mock.text.description(itemIndex),
  })),
  shareLinks: _mock.shareLinks,
}));

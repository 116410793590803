// ----------------------------------------------------------------------

export const paths = {
  // Hotel
  hotel: {
    landing: '/hotel/landing',
    lists: '/hotel/lists',
    list: (id) => `/hotel/list/${id}`,
    checkout: '/hotel/checkout',
    orderCompleted: '/hotel/order-completed',
    posts: '/hotel/posts',
    post: `/hotel/post`,
    about: '/hotel/about',
    contact: '/hotel/contact',
  },
  // E-learning
  eLearning: {
    landing: '/e-learning/landing',
    courses: '/e-learning/courses',
    course: `/e-learning/course`,
    posts: '/e-learning/posts',
    post: `/e-learning/post`,
    about: '/e-learning/about',
    contact: '/e-learning/contact',
  },
  // E-commerce
  eCommerce: {
    landing: '/',
    products: (keyword, name) => `/products/${keyword}/${name}`,
    merchantViewProducts: `/merchantViewProducts`,
    merchantChat: `/merchant/chat`,
    product: (name) => `/product/${name}`,
    cart: '/cart',
    checkout: `/checkout`,    
    appProceedCart: (type, id) => `/emporiaapp/${type}/${id}`,
    proceedBuyNow: (id) => `/emporiabuynow/${id}`,
    orderCompleted:(paymentType, transactionNo)=> `/shop/ShopPageReceipt/${paymentType}/${transactionNo}`,
    // orderCompleted: '/order-completed',
    wishlist: `/wishlist`,
    compare: `/compare`,
    account: {
      root: `/account`,
      personal: `/account/personal`,
      address: `/account/address`,
      wishlist: `/account/wishlist`,
      vouchers: `/account/vouchers`,
      orders: `/account/orders`,
      orderDetail: (id) => `/account/orderDetail/${id}`,
      payment: `/account/payment`,
    },
    merchantProduct: (merchantID) => `/merchantProduct/${merchantID}`,    
  },
  youthEmpower: `/youthEmpower`,

  tripPackage: {
    root: `/tripPackage/landing`,
    package: (locationId) => `/tripPackage/package/${locationId}`,
    // package: (locationId, locationinfo) =>`/tripPackage/package/${locationId}/${locationinfo}`,
    packageLocation: (tripLocationDetailsID) => `/tripPackage/packageLocation/${tripLocationDetailsID}`,
  },

  directory: {
    root: `/directory/landing`, 
  },
  
  // Booklah Event
  booklahEvent: {
    root: `/booklahEvent/landing`,
    event: (id) => `/booklahEvent/event/${id}`,
    checkout: `/booklahEvent/checkout`,    
    buyNow: (id) => `/booklahEvent/buynow/${id}`,
  },
  // Auth
  loginCover: '/auth/login-cover',
  loginBackground: '/auth/login-background',
  loginIllustration: '/auth/login-illustration',
  registerCover: '/auth/register-cover',
  registerBackground: '/auth/register-background',
  registerIllustration: '/auth/register-illustration',
  resetPassword: '/auth/reset-password',
  verifyCode: '/auth/verify-code',
  registerMerchant: (userID)=>'/auth/register-merchant',
  // Common
  maintenance: '/maintenance',
  comingsoon: '/coming-soon',
  pricing01: '/pricing-01',
  pricing02: '/pricing-02',
  payment: '/payment',
  support: '/support',
  page404: '/404',
  page500: '/500',
  externalOrderDetail: (id) => `/ticketDetail/${id}`,
};
